import funboxmusicLogo from "../assets/images/funboxmusic.png";
const AWS_BUCKET = 'https://sources.megaplayer.me';

const FUNBOXMUSIC = {
    logo: funboxmusicLogo,
    name: 'FUNBOXMUSIC',
    favicon: AWS_BUCKET+'/favicon/funbox.ico',
    web: 'www.funboxmusic.com',
    supportContact: 'info@funboxmusic.com',
    url_downloads: 'https://funboxmusic.com/descargas',
    url_web_player_p: 'https://player.funboxmusic.com',
    url_web_player_t: 'https://webplayertest.megaplayer.me/session/signIn/2',
    url_install_win: AWS_BUCKET+'/installers/install_funboxmusic_',
    url_install_macos: null,
    url_google_play_store: 'https://play.google.com/store/apps/details?id=com.funboxmusic.funboxmusic',
    url_aptoide: null,
    url_apk_x32: AWS_BUCKET+'/installers/app_funboxmusic_x32.apk',
    url_apk_x64: AWS_BUCKET+'/installers/app_funboxmusic_x64.apk',
    url_support_i: 'https://funboxmusic.com/descargas/#soporte',
    url_support_m: 'https://funboxmusic.com/descargas/#soporte',
    url_whatsapp: null,
}

export default FUNBOXMUSIC;