import kasimuLogo from "../assets/images/kasimu.png"
const AWS_BUCKET = 'https://sources.megaplayer.me';

const KASIMU = {
    logo: kasimuLogo,
    name: 'Kasimu',
    favicon: AWS_BUCKET+'/favicon/kasimu.ico',
    web: 'www.kasimu.com',
    supportContact: 'soporte@kasimu.com',
    url_downloads: null,
    url_web_player_p: 'https://webplayer.kasimu.com',
    url_web_player_t: 'https://webplayertest.megaplayer.me/session/signIn/0',
    url_install_win: AWS_BUCKET+'/installers/install_kasimu_',
    url_install_macos: null,
    url_google_play_store: 'https://play.google.com/store/apps/details?id=com.virtualdj.Kasimu',
    url_aptoide: 'https://kasimu-virtual-d-j-sl.es.aptoide.com/app',
    url_apk_x32: AWS_BUCKET+'/installers/app_kasimu_x32.apk',
    url_apk_x64: AWS_BUCKET+'/installers/app_kasimu_x64.apk',
    url_support_i: 'https://www.kasimu.com/soporte-individual/',
    url_support_m: 'https://www.kasimu.com/soporte-multiusuario/',
    url_whatsapp: 'https://web.whatsapp.com/send?phone=34606025800'
}

export default KASIMU;