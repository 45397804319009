import React, { Component } from "react";
import { observer } from "mobx-react";
import withStore from "../../../hocs/withStore";
import {
  Text,
  Panel,
  Button,
  Field,
  Paragraph,
  Columns,
  Column,
} from "ks_storybook";
import ReactTooltip from "react-tooltip";
import CopyToClipboard from "react-copy-to-clipboard";
import { withToastManager } from "react-toast-notifications";

const PanelContent = (props) => {
  const { children, isMobile, style } = props;
  if (isMobile) {
    return (
      <Panel color="gray" padding="25px 22px" style={style}>
        {children}
      </Panel>
    );
  }
  return children;
};

@observer
class DownloadsPage extends Component {
  constructor(props) {
    super(props);

    this.state = {
      version: null,
      url_downloads: null,
      url_web_player: null,
      url_install_win: null,
      url_install_macos: null,
      url_google_play_store: null,
      url_aptoide: null,
      url_apk_x32: null,
      url_apk_x64: null,
      lang: this.props.store.lang
    };
  }

  componentDidMount() {
    this.getVersion(1);
    this.genURL();
    this.props.store.menuTitle = this.props.store.language.label701;
  }

  componentDidUpdate() {
    if (this.state.lang !== this.props.store.lang) {
      this.setState({ lang: this.props.store.lang });
      this.genURL();
    }
  }

  getVersion = (id) => {
    this.props.store.kasimuAPIClient
      .search("/get_version", { id: id })
      .then((res) => {
        if (res.results) {
          this.setState({ version: res.results.version_app });
        }
      });
  };

  openLink = (link, type) => {
    return window.open(link, type);
  };

  getInstallWinLang = (lang) => {
    switch (lang) {
      case 1:
        return "es";
      default:
        return "en";
    }
  };

  genURL = () => {
    const { currentCompany, getProduction, lang } = this.props.store;
    const url_web_player = getProduction() == 1
    ? currentCompany.url_web_player_p
    : currentCompany.url_web_player_t;
    this.setState({
      url_downloads: currentCompany.url_downloads,
      url_web_player: url_web_player,
      url_install_win: currentCompany.url_install_win+this.getInstallWinLang(lang)+".exe",
      url_install_macos: currentCompany.url_install_macos,
      url_google_play_store: currentCompany.url_google_play_store,
      url_aptoide: currentCompany.url_aptoide,
      url_apk_x32: currentCompany.url_apk_x32,
      url_apk_x64: currentCompany.url_apk_x64,
    });
  };

  render() {
    const {
      version,
      url_downloads,
      url_web_player,
      url_install_win,
      url_install_macos,
      url_google_play_store,
      url_aptoide,
      url_apk_x32,
      url_apk_x64,
    } = this.state;
    const { toastManager } = this.props;
    const mobileStyle = {
      display: "flex",
      flexDirection: "row",
      alignItems: "center",
      justifyContent: "space-between",
    };
    return (
      <React.Fragment>
        <Columns>
          <Column isSize={{ mobile: 12, desktop: 12, tablet: 12 }}>
            {this.props.store.viewMobile && (
              <>
                <Text
                  size={this.props.store.viewMobile ? "lg" : "md"}
                  lead
                  weight="black"
                  lineBreak
                >
                  {this.props.store.language.label701}
                </Text>
                <br />
              </>
            )}

            <Columns
              style={{
                gap: !this.props.store.viewMobile && "8px",
              }}
              className={this.props.store.viewMobile ? "mb-3" : "mb-1"}
            >
              {url_downloads && <Column isSize={{ mobile: 12, desktop: 12, tablet: 12 }}>
                <PanelContent isMobile={!this.props.store.viewMobile}>
                  <Columns>
                    <Column isSize={{ mobile: 12, desktop: 2, tablet: 12 }}>
                      <Text
                        size={this.props.store.viewMobile ? "md" : "sm"}
                        lead
                        weight="strong"
                        lineBreak
                      >
                        {this.props.store.language.label702}
                      </Text>
                    </Column>

                    <Column isSize={{ mobile: 12, desktop: 8, tablet: 12 }}>
                      <Text
                        color="special"
                        size={this.props.store.viewMobile ? "xl" : "lg"}
                        multiline
                      >
                        {this.props.store.language.label703}
                      </Text>
                      <Text
                        size={this.props.store.viewMobile ? "xl" : "lg"}
                        multiline
                      >
                        <div className="is-row is-fullwidth items-center mt-1">
                          {"\n" + url_downloads}
                          <div data-tip data-for="copy">
                            <CopyToClipboard
                              text={url_downloads}
                              onCopy={() =>
                                toastManager.add(
                                  this.props.store.language.label723,
                                  {
                                    appearance: "success",
                                    autoDismiss: true,
                                  }
                                )
                              }
                            >
                              <Button
                                className="ml-2"
                                icon="copy"
                                kind="link"
                                color="special"
                                size={this.props.store.viewMobile ? "lg" : "md"}
                                lead
                                space
                              />
                            </CopyToClipboard>
                          </div>
                        </div>
                      </Text>
                    </Column>
                  </Columns>
                </PanelContent>
                <br />
              </Column>}

              <Column isSize={{ mobile: 12, desktop: 9, tablet: 12 }}>
                <PanelContent isMobile={!this.props.store.viewMobile}>
                  <Text
                    size={this.props.store.viewMobile ? "md" : "sm"}
                    lead
                    weight="strong"
                    lineBreak
                  >
                    {this.props.store.language.label704 + " (" + version + ")"}
                  </Text>
                </PanelContent>
                <Columns
                  className="mt-1"
                  style={{
                    gap: !this.props.store.viewMobile && "0px",
                  }}
                >
                  <Column isSize={{ mobile: 12, desktop: 3, tablet: 12 }}>
                    <Field className="mb-1">
                      <PanelContent
                        isMobile={!this.props.store.viewMobile}
                        style={!this.props.store.viewMobile && mobileStyle}
                      >
                        <Text
                          color="special"
                          size={this.props.store.viewMobile ? "xl" : "lg"}
                          className={this.props.store.viewMobile && "mb-1"}
                        >
                          {"Windows"}
                        </Text>
                        <Button
                          size={this.props.store.viewMobile ? "lg" : "md"}
                          className="px-3"
                          onClick={() => {
                            this.openLink(url_install_win, "_self");
                          }}
                        >
                          {this.props.store.language.label157}
                        </Button>
                      </PanelContent>
                    </Field>
                    {url_install_macos && (
                      <Field className="mb-1">
                        <PanelContent
                          isMobile={!this.props.store.viewMobile}
                          style={!this.props.store.viewMobile && mobileStyle}
                        >
                          <Text
                            color="special"
                            size={this.props.store.viewMobile ? "xl" : "lg"}
                            className={this.props.store.viewMobile && "mb-1"}
                          >
                            {"MacOs"}
                          </Text>
                          <Button
                            size={this.props.store.viewMobile ? "lg" : "md"}
                            className="px-3"
                            onClick={() => {
                              this.openLink(url_install_macos, "_self");
                            }}
                          >
                            {"Beta"}
                          </Button>
                        </PanelContent>
                      </Field>
                    )}
                  </Column>
                  <Column isSize={{ mobile: 12, desktop: 3, tablet: 12 }}>
                    <Field className="mb-1">
                      <PanelContent
                        isMobile={!this.props.store.viewMobile}
                        style={!this.props.store.viewMobile && mobileStyle}
                      >
                        <Text
                          color="special"
                          size={this.props.store.viewMobile ? "xl" : "lg"}
                          className={this.props.store.viewMobile && "mb-1"}
                        >
                          {"Android"}
                        </Text>
                        <Button
                          size={this.props.store.viewMobile ? "lg" : "md"}
                          className="px-3"
                          onClick={() => {
                            this.openLink(url_google_play_store, "");
                          }}
                        >
                          {"Play Store"}
                        </Button>
                      </PanelContent>
                    </Field>
                    {url_aptoide && (
                      <Field className="mb-1">
                        <PanelContent
                          isMobile={!this.props.store.viewMobile}
                          style={!this.props.store.viewMobile && mobileStyle}
                        >
                          <Text
                            color="special"
                            size={this.props.store.viewMobile ? "xl" : "lg"}
                            className={this.props.store.viewMobile && "mb-1"}
                          >
                            {"Android"}
                          </Text>
                          <Button
                            size={this.props.store.viewMobile ? "lg" : "md"}
                            className="px-3"
                            onClick={() => {
                              this.openLink(url_aptoide, "");
                            }}
                          >
                            {"Aptoide"}
                          </Button>
                        </PanelContent>
                      </Field>
                    )}
                  </Column>
                  <Column isSize={{ mobile: 12, desktop: 3, tablet: 12 }}>
                    <Field className="mb-1">
                      <PanelContent
                        isMobile={!this.props.store.viewMobile}
                        style={!this.props.store.viewMobile && mobileStyle}
                      >
                        <Text
                          color="special"
                          size={this.props.store.viewMobile ? "xl" : "lg"}
                          className={this.props.store.viewMobile && "mb-1"}
                        >
                          {"Android"}
                        </Text>
                        <Button
                          size={this.props.store.viewMobile ? "lg" : "md"}
                          className="px-3"
                          onClick={() => {
                            this.openLink(url_apk_x32, "_self");
                          }}
                        >
                          {"32 bits"}
                        </Button>
                      </PanelContent>
                    </Field>
                    <Field className="mb-1">
                      <PanelContent
                        isMobile={!this.props.store.viewMobile}
                        style={!this.props.store.viewMobile && mobileStyle}
                      >
                        <Text
                          color="special"
                          size={this.props.store.viewMobile ? "xl" : "lg"}
                          className={this.props.store.viewMobile && "mb-1"}
                        >
                          {"Android"}
                        </Text>
                        <Button
                          size={this.props.store.viewMobile ? "lg" : "md"}
                          className="px-3"
                          onClick={() => {
                            this.openLink(url_apk_x64, "_self");
                          }}
                        >
                          {"64 bits"}
                        </Button>
                      </PanelContent>
                    </Field>
                  </Column>
                  {url_web_player && <Column isSize={{ mobile: 12, desktop: 3, tablet: 12 }}>
                    <Field NoteSize="xs">
                      <PanelContent
                        isMobile={!this.props.store.viewMobile}
                        style={!this.props.store.viewMobile && mobileStyle}
                      >
                        <Text
                          color="special"
                          size={this.props.store.viewMobile ? "xl" : "lg"}
                          className={this.props.store.viewMobile && "mb-1"}
                        >
                          {"Web Player"}
                        </Text>
                        <div className="flex items-center">
                          <Button
                            size={this.props.store.viewMobile ? "lg" : "md"}
                            className="px-3"
                            onClick={() => {
                              this.openLink(url_web_player, "");
                            }}
                          >
                            {this.props.store.language.label705}
                          </Button>
                          <div data-tip data-for="copy-webplayer">
                            <CopyToClipboard
                              text={url_web_player}
                              onCopy={() =>
                                toastManager.add(
                                  this.props.store.language.label724,
                                  { appearance: "success", autoDismiss: true }
                                )
                              }
                            >
                              <Button
                                className="ml-2"
                                icon="copy"
                                kind="link"
                                color="special"
                                size="lg"
                                lead
                                space
                              />
                            </CopyToClipboard>
                          </div>
                        </div>
                      </PanelContent>
                    </Field>
                  </Column>}
                </Columns>
              </Column>
            </Columns>
          </Column>
        </Columns>
        <PanelContent isMobile={!this.props.store.viewMobile}>
          <Columns>
            <Column isSize={{ mobile: 12, desktop: 11, tablet: 12 }}>
              <Text
                size={this.props.store.viewMobile ? "md" : "md"}
                lead
                weight="strong"
                lineBreak
              >
                {this.props.store.language.label706}
              </Text>
              <Text
                className="mt-2"
                size={this.props.store.viewMobile ? "xl" : "lg"}
                weight="strong"
                lineBreak
              >
                {"Windows"}
              </Text>
              <Text
                className="mt-1"
                size={this.props.store.viewMobile ? "xl" : "lg"}
                weight="medium"
                color="special"
                multiline
              >
                {this.props.store.language.label698}
                <Text
                  className="ml-2 mt-2"
                  size={this.props.store.viewMobile ? "xl" : "lg"}
                  weight="strong"
                  lineBreak
                >
                  {this.props.store.language.label707}
                </Text>
                <ul className="ml-4 mt-1">
                  <li>{"- " + this.props.store.language.label708}</li>
                  <li>{"- " + this.props.store.language.label709}</li>
                  <li>{"- " + this.props.store.language.label710}</li>
                  <li>{"- " + this.props.store.language.label711}</li>
                  <li>{"- " + this.props.store.language.label712}</li>
                </ul>
              </Text>
              {url_install_macos && (
                <Text
                  className="mt-2"
                  size={this.props.store.viewMobile ? "xl" : "lg"}
                  weight="strong"
                  lineBreak
                >
                  {"MacOS"}
                </Text>
              )}
              {url_install_macos && (
                <Text
                  color="special"
                  className="mt-1"
                  size={this.props.store.viewMobile ? "xl" : "lg"}
                  weight="medium"
                  multiline
                >
                  {this.props.store.language.label699}

                  <Text
                    className="ml-2 mt-2"
                    size={this.props.store.viewMobile ? "xl" : "lg"}
                    weight="strong"
                    lineBreak
                  >
                    {this.props.store.language.label707}
                  </Text>
                  <ul className="ml-4 mt-1">
                    <li>{"- " + this.props.store.language.label730}</li>
                    <li>{"- " + this.props.store.language.label709}</li>
                    <li>{"- " + this.props.store.language.label711}</li>
                    <li>{"- " + this.props.store.language.label712}</li>
                  </ul>
                </Text>
              )}
              <Text
                className="mt-2"
                size={this.props.store.viewMobile ? "xl" : "lg"}
                weight="strong"
                lineBreak
              >
                {"Android"}
              </Text>
              <Text
                className="mt-1"
                color="special"
                size={this.props.store.viewMobile ? "xl" : "lg"}
                weight="medium"
                multiline
              >
                {this.props.store.language.label700}
                <Text
                  className="ml-2 mt-2"
                  size={this.props.store.viewMobile ? "xl" : "lg"}
                  weight="strong"
                  lineBreak
                >
                  {this.props.store.language.label707}
                </Text>
                <ul className="ml-4 mt-1">
                  <li>{"- " + this.props.store.language.label713}</li>
                  <li>{"- " + this.props.store.language.label709}</li>
                  <li>{"- " + this.props.store.language.label714}</li>
                  <li>{"- " + this.props.store.language.label712}</li>
                </ul>
              </Text>
              <br />
            </Column>
          </Columns>
        </PanelContent>
        {this.props.store.viewMobile && (
          <ReactTooltip id="copy" place="bottom" type="dark" effect="float">
            <span>{this.props.store.language.label715}</span>
          </ReactTooltip>
        )}
        {this.props.store.viewMobile && (
          <ReactTooltip
            id="copy-webplayer"
            place="bottom"
            type="dark"
            effect="float"
          >
            <span>{this.props.store.language.label716}</span>
          </ReactTooltip>
        )}
      </React.Fragment>
    );
  }
}

export default withStore(withToastManager(DownloadsPage));
